import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

import SEO from '../components/seo';
import { parseLocale } from '../locale';

import styled from '@emotion/styled';

const Wrapper = styled.div`
  min-height: 400px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    min-height: 300px;
    padding: 20px;
  }
`;

const NotFoundPage: React.FC = () => {
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: '404' },
      { locale: 'fr', slug: '404' },
    ],
  });

  return (
    <>
      <SEO lang={locale} title={intl.formatMessage({ id: 'page_not_found' })} />
      <Wrapper>
        <h1>{intl.formatMessage({ id: 'page_not_found' })}</h1>
        <p>{intl.formatMessage({ id: 'page_not_found_long' })}</p>
      </Wrapper>
    </>
  );
};

export default NotFoundPage;
